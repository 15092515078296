
// Libraries
import * as React from 'react'
import moment from 'moment'

// Services
import ApiService from "../../../services/api"

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import DuoSection from "../../../components/duoSection"
import AdminVenueViewRow from "../../../components/admin/venues/adminVenueViewRow"
import InputFactory from "../../../components/inputFactory"
import Button from "../../../components/button"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminVenuesNewPage extends React.Component {
	state = {
		inputValues: {
			address: {
				is_primary: 1,
				is_billing: 1,
				is_shipping: 1,
				country_code: 'GB'
			},
			default_max_tickets_per_transaction: 2,
			default_door_times_time: moment('19:00', 'HH:mm').format('HH:mm'),
			default_starts_time_time: moment('20:00', 'HH:mm').format('HH:mm')
		},
		inputErrors: {
			address: {},
		},
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const {inputValues, inputErrors, isSubmitting} = this.state
		const addressErrors = inputErrors ? {
			street: inputErrors['address.street'],
			street_2: inputErrors['address.street_2'],
			state: inputErrors['address.state'],
			postal_code: inputErrors['address.postal_code'],
			city: inputErrors['address.city'],
			region: inputErrors['address.region']
		} : {}

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-form nav-blue-half" hideSideNav pageAdminForm>
				<Seo title="New Venue" />
				<DuoSection adminForm>
					<div>
						<h1>New Venue</h1>
					</div>
					<div className="admin-form__wrap">
						<div className="admin-form">
							<div className="admin-form__form">
								<form onSubmit={this.handleSubmit} encType="multipart/form-data">
									<div className="admin-form__field-group">
										<h2>Venue</h2>
										<AdminVenueViewRow
											label="Venue Name*"
											value={<InputFactory
												type="text"
												name="name"
												value={inputValues?.name}
												error={inputErrors?.name}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Address"
											value={<InputFactory
												type="address"
												name="address"
												value={inputValues?.address}
												error={addressErrors}
												onChange={this.handleInputChange}/>}
										/>
									</div>
									<div className="admin-form__field-group">
										<h2>Links and Website</h2>
										<AdminVenueViewRow
											label="Website Link"
											value={<InputFactory
												type="text"
												name="website_url"
												value={inputValues?.website_url}
												error={inputErrors?.website_url}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Directions Link"
											value={<InputFactory
												type="text"
												name="directions_url"
												value={inputValues?.directions_url}
												error={inputErrors?.directions_url}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Customer Service Email"
											value={<InputFactory
												type="email"
												name="customer_service_email"
												value={inputValues?.customer_service_email}
												error={inputErrors?.customer_service_email}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Customer Service Link"
											value={<InputFactory
												type="text"
												name="customer_service_url"
												value={inputValues?.customer_service_url}
												error={inputErrors?.customer_service_url}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Security Information Link "
											value={<InputFactory
												type="text"
												name="security_information_url"
												value={inputValues?.security_information_url}
												error={inputErrors?.security_information_url}
												onChange={this.handleInputChange}
											/>} />
									</div>

									<div className="admin-form__field-group">
										<h2>Default Event Values</h2>
										<AdminVenueViewRow
											label="Default Door Times"
											value={<InputFactory
												type="time"
												name="default_door_times_time"
												value={inputValues?.default_door_times_time}
												error={inputErrors?.default_door_times_time}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Default Starts Time"
											value={<InputFactory
												type="time"
												name="default_starts_time_time"
												value={inputValues?.default_starts_time_time}
												error={inputErrors?.default_starts_time_time}
												onChange={this.handleInputChange}
											/>} />
										<AdminVenueViewRow
											label="Default Max Tickets"
											value={<InputFactory
												type="number"
												step="1"
												min="0"
												name="default_max_tickets_per_transaction"
												value={inputValues?.default_max_tickets_per_transaction}
												error={inputErrors?.default_max_tickets_per_transaction}
												onChange={this.handleInputChange}
											/>} />
									</div>

									<div className="admin-form__submit-wrap">
										<Button type="button" to="/admin/venues" colorEndeavour hollow>Cancel</Button>
										<Button type="submit" isLoading={isSubmitting} colorEndeavour>Add Venue</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</DuoSection>
			</LayoutAdmin>
		</PrivateRoute>
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {inputValues} = this.state
		const apiService = new ApiService()

		// prepare venue data
		inputValues['description'] = 'Description'
		inputValues['address']['given_name'] = inputValues['name']
		inputValues['address']['family_name'] = ' '
		const venueData = JSON.stringify(inputValues)

		const {addNotification} = this.context

		// submit venue data
		await apiService.post(`venues`, {body: venueData})
			.then(async (venueResponse) => {
				if (venueResponse.success) {
					addNotification('New Venue Created', 'success')
					this.props.navigate('/admin/venues/')
				}
				else {
					this.setState({
						inputErrors: venueResponse.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminVenuesNewPage
